export const siteModalsStrings = [
  'accountBalances',
  'accountLocked',
  'accountRestricted',
  'accountUnlocked',
  'balances',
  'category',
  'rGExclusionMessage',
  'rGLimitsMessage',
  'filters',
  'gameShare',
  'login',
  'offline',
  'passwordResetStepOne',
  'passwordResetStepSuccess',
  'passwordResetStepTwo',
  'register',
  'registrationFullCompletion',
  'registrationPartialCompletion',
  'registrationSecurityQuestions',
  'registrationVerifyName',
  'registrationWelcomeOffer',
  'search',
  'sessionLimitExceeded',
  'transactionAssignBonus',
  'wagerLimitExceeded',
  'coolingOffConfirm',
  'withdrawalAlert'
] as const;

export const AccountModalsStrings = [
  'account-settings',
  'bonus-summary',
  'deposit',
  'document-verification',
  'history',
  'logout',
  'password-management',
  'password-management-success',
  'personal-details',
  'responsible-gaming',
  'settings',
  'teller',
  'transaction-summary',
  'transaction-summary-detail',
  'withdrawal',
] as const;

export type AccountModals = (typeof AccountModalsStrings)[number];
export type Modals = (typeof siteModalsStrings)[number];
